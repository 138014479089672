import React from "react";
import ArticleFeed from "../components/articlefeed";

import Layout from "../components/layout";
import SEO from "../components/seo";
import RelatedContent from "../components/relatedcontent";
import { graphql } from "gatsby";

const iot = require("../images/iot.jpeg");

const InternetOfThingsNewsPage = ({ data }: any) => {
  data.allPrismicArticle.edges.sort(
    (a: any, b: any) =>
      new Date(b.node.data.date).valueOf() -
      new Date(a.node.data.date).valueOf()
  );
  return (
    <Layout>
      <SEO title="IoT News" description="" lang="en" meta={[]} />
      {/* Header Banner */}
      <div
        className="container banner white"
        style={{ backgroundImage: `url(${iot})` }}
      >
        <h1>Internet of Things</h1>
      </div>

      {/* News Section */}
      <div className="container">
        <h2>Latest News</h2>
        <div className="row">
          <ArticleFeed data={data} />
          <div className="col-lg-4">
            <RelatedContent />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicArticle(filter: { tags: { in: "IoT" } }) {
      edges {
        node {
          tags
          uid
          data {
            date
            header_image {
              url
            }
            header_image_caption {
              text
            }
            title {
              text
            }
            author {
              text
            }
          }
        }
      }
    }
  }
`;

export default InternetOfThingsNewsPage;
